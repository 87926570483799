@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.unauthenticated-content {
  background: url('../public/IntegratedPortal_Login_NewUI_1_bg.jpg') no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disclaimertitle {
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 30px;
  line-height: 40.85px;
  letter-spacing: 0px;
  text-align: center;  
  color: #005CBB;
  margin-top: 56px;
}

.disclaimercontent {
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
  color: #181C23;
  padding: 24px 56px;
}

.disclaimerbutton {
  background-color: #005CBB;
  color: #FFFFFF;
  display: block;
  margin: auto;
  width: 419px;
  height: 82px;
  gap: 8px;
  border-radius: 4px;
  padding-top: 24px;
  padding-right: 40px;
  padding-bottom: 24px;
  padding-left: 40px;
  border: none;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-weight: 600;
  font-size: 18px;
  line-height: 34px;
  letter-spacing: 0px;
  text-align: center;
}