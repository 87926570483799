@import "../../themes/generated/variables.base.scss";


.login-form {
    .link {
      text-align: center;
      font-size: 16px;
      font-style: normal;
  
      a {
        text-decoration: none;
      }
    }
  
    .form-text {
      margin: 10px 0;
      color: rgba($base-text-color, alpha($base-text-color) * 0.7);
    }
  }
  