/* Breadcrumb.css */
.breadcrumb-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    padding: 0 40px;
}

.breadcrumb-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.breadcrumb-title {
    margin: 0 !important;
    padding: 0.5rem 0 0 0;
    font-size: 1.5rem;
    font-weight: bold;
}

.breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    border-radius: 0.25rem;
}

.breadcrumb-item {
    display: flex;
    align-items: center;
    color: #007bff;
    text-decoration: none;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: '>';
    padding: 0 0.5rem;
    color: #6c757d;
}

.breadcrumb-item.active {
    color: #6c757d;
    pointer-events: none;
}

.breadcrumb-item a {
    color: inherit;
    text-decoration: none;
}

.breadcrumb-item a:hover {
    text-decoration: underline;
}

.breadcrumb-action {
    /* Add any additional styling for the action item here */
    padding: 1rem 0 0 0;
}