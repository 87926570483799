.custom-table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #ddd;
}
  
.custom-table th,
.custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}
  
.custom-table th {
    background-color: #f2f2f2;
}

.dx-field-button-item {
    margin-top: auto;
}