@import "../../themes/generated/variables.base.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');


.single-card {

  .cardwrapper {
    height: 100%;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }

  .dx-card {
    // width: 568px;
    min-height: 626px;
    height: auto;
    // margin: auto auto;
    margin: 40px 40px;
    padding: 24px 50px;
    flex-grow: 0;
    justify-content: center;
    width: 400px;
    // margin-bottom: 40px;
    // margin-top: 40px;


    .screen-x-small & {
      width: 100%;
      height: 100%;
      border-radius: 0;
      box-shadow: none;
      margin: 0;
      border: 0;
      flex-grow: 1;
    }

    .header {
      // margin-bottom: 30px;
    //  justify-items: center;

      .title {
        color: $base-text-color;
        line-height: 28px;
        font-weight: 500;
        font-size: 18px;
        justify-items: center;
        text-align: center;

      }

      .description {
        color: rgba($base-text-color, alpha($base-text-color) * 0.7);
        line-height: 18px;
      }

      .logo {
        // display: flex;
        // justify-content: center;
        align-items: center;
        width: 65%;
        display: block;
        margin: 0 auto;
      }

    }

    .integrated-portal-text {
      color: #005CBB;
      text-align: center;
    }

  }

  .integrated-portal-text {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    line-height: 34px;
    letter-spacing: 0px;
    text-align: center;
    margin-top: 0px;
  }

  .cardcontent {
    // margin-left: 82px;
    // margin-right: 82px;
    width: 100%;
  }

}