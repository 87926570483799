.card-container {
    display: flex;
    flex-wrap: wrap; /* Ensure items do not wrap to the next line */
    gap: 20px;
    padding: 20px 0;
    justify-content: space-between; /* Distribute space between items */
}


.card {
    flex: 1 1 calc(50% - 20px); /* Ensure each card takes up 50% of the container width minus the gap */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    width: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-info {
    display: flex;
    flex-direction: column;
    align-items: start;
}

.user-info-item {
    margin-bottom: 10px;
}

.label {
    font-weight: bold;
    margin-right: 10px;
}

.value {
    color: #555; /* Adjust the color as needed */
}

.donuttitle {
    font-size: 12px;
    text-align: center;
}

.donutpercentage {
    font-size: 12px;
    text-align: center;
}