@import "../../themes/generated/variables.base.scss";

.recaptcha-container {
  width: 100%;
  display: flex;
  // justify-content: center;
}

.recaptcha-container>div {
  transform: scale(0.99);
  -webkit-transform: scale(0.99);
  transform-origin: 0 0;
  -webkit-transform-origin:0 0; 
}

@media (max-width: 480px) {
  .recaptcha-container>div {
    transform: scale(0.82);
    -webkit-transform: scale(0.82);
  }
}

@media (max-width: 390px) {
  .recaptcha-container>div {
    transform: scale(0.69);
    -webkit-transform: scale(0.69);
  }
}

.login-link {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }
}

.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) .custom-button-padding{
  padding-top: 18px; 
}

.custom-button-padding {
  padding-top: 18px;
}
/* Ensure the custom class has higher specificity */
.custom-button-padding.dx-field-item:not(.dx-field-item-has-group):not(.dx-field-item-has-tabs):not(.dx-first-row):not(.dx-label-v-align) {
  padding-top: 18px; /* Set padding top to 22px */
}